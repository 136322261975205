<template>
  <div v-if="orderDetail.order_item">
    <b-overlay
      :show="loading"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <p class="title font-weight-bold">{{ $t("lbl_return_the_results") }}</p>

      <result-form-item
        v-for="(item, index) in orderDetail.order_item.OrderItemAttachments"
        :key="item.id"
        :form="item"
        :index="index"
        :orderId="orderDetail.order_id"
        :isReject="isReject"
        :isCompletedOrder="isCompletedOrder"
        :isPendingOrder="isPendingOrder"
        :isCancelledOrder="isCancelledOrder"
        :hasDraftData="hasDraftData"
        @upload="handleUploadFile"
        @delete="item.url = ''"
      ></result-form-item>

      <div v-if="!isCompletedOrder" class="flex justify-center mt-2">
        <span
          v-b-tooltip.top
          :title="
            $t(isUploadAllFile ? '' : 'lbl_please_upload_all_result_files')
          "
        >
          <b-button
            :disabled="!isUploadAllFile"
            @click="handleSubmit"
            variant="primary"
            >{{ $t("lbl_return_the_results") }}</b-button
          >
        </span>
      </div>
    </b-overlay>

    <ModalResultFormProcessor
      @onSuccess="handleCreateResultFormSuccess"
      :orderDetail="orderDetail"
    />
  </div>
</template>
<script>
import Vue from "vue";
import {
  ORDER_FORM_DRAFT,
  ORDER_FORM_PROCESSOR_STATUS,
  RESULT_TYPE,
} from "../../../../utils/constant";
import ModalResultFormProcessor from "./ModalResultFormProcessor.vue";
import ResultFormItem from "./ResultFormItem.vue";

export default {
  components: {
    ModalResultFormProcessor,
    ResultFormItem,
  },
  name: "Result",
  props: {
    orderDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      hasDraftData: false,
    };
  },
  computed: {
    isPendingOrder() {
      return this.orderDetail?.status === ORDER_FORM_PROCESSOR_STATUS.PENDING;
    },
    isUploadAllFile() {
      return this.orderDetail?.order_item?.OrderItemAttachments?.every(
        (item) => item?.url
      );
    },
    isDisabledButton() {
      return (
        !this.isUploaddAllFile ||
        this.orderDetail?.processor_state[
          this.orderDetail?.processor_state.length - 1
        ]?.time_changed
      );
    },
    isCompletedOrder() {
      return this.orderDetail?.status === ORDER_FORM_PROCESSOR_STATUS.COMPLETE;
    },
    isReject() {
      return this.orderDetail?.status === ORDER_FORM_PROCESSOR_STATUS.CANCEL;
    },
    isCancelledOrder() {
      return (
        this.orderDetail?.status === ORDER_FORM_PROCESSOR_STATUS.SUPPLIER_CANCEL
      );
    },
  },
  watch: {
    orderDetail: {
      deep: true,
      handler() {
        this.handleGetDraft();
      },
    },
  },
  beforeMount() {
    this.handleGetDraft();
  },
  methods: {
    handleSubmit() {
      this.$emit("changeStatus", {
        id: this.orderDetail.id,
        status: ORDER_FORM_PROCESSOR_STATUS.COMPLETE,
        successTitle: this.$t("lbl_results_returned_successfully"),
        errorTitle: this.$t("lbl_failed_to_return_the_results"),
        order_item_ids: [this.orderDetail.id],
        order_item_attachment_ids: [
          this.orderDetail.order_item.OrderItemAttachments?.map(
            (elm) => elm.ID
          )[0],
        ],
      });
    },
    async handleCreateResultFormSuccess(url) {
      const paramsReturnResult = {
        attachments: [
          {
            attachment_ids:
              this.orderDetail.order_item?.OrderItemAttachments?.length &&
              this.orderDetail.order_item?.OrderItemAttachments[0] &&
              this.orderDetail.order_item?.OrderItemAttachments[0]?.ID
                ? [this.orderDetail.order_item?.OrderItemAttachments[0]?.ID]
                : [],
            url: url,
            group_name: "Phiếu kết quả",
            order_id: this.orderDetail?.order_id,
          },
        ],
        type: RESULT_TYPE.PROCESSOR,
      };
      // store
      //   .dispatch('orders/updateAttachmentResult', {
      //     params,
      //     callback: () => {}
      //   })
      await Vue.prototype.$rf
        .getRequest("CommonRequest")
        .processorReturnResult(paramsReturnResult)

        .then(() => {
          this.loading = false;
          this.$emit("reload");
        });
    },
    async handleGetDraft() {
      if (!this.orderDetail?.order_item_id) return;
      const orderItemId = Number(this.orderDetail.order_item_id);

      try {
        const params = {
          order_item_id: orderItemId,
          order_form_type: ORDER_FORM_DRAFT.RESULT_PROCESSOR,
        };

        const response = await this.$rf
          .getRequest("CommonRequest")
          .getDraftOrderForm(params);

        const responseData = response.data?.data;
        this.hasDraftData = !!responseData?.content;
      } catch (error) {
        console.log(error);
        this.hasDraftData = false;
      }
    },
    async handleUploadFile(result) {
      this.$emit("reload", result);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-name {
  font-weight: 600;
}

.description {
  font-size: 12px;

  a {
    text-decoration: underline;
  }
}

.save-btn {
  width: 50%;

  transform: translate(50%, 100%);
}

.cs-link {
  :hover {
    text-decoration: underline;
  }
}

.cs-box-item {
  border: 1px solid #20409b;
  padding: 12px;
  border-radius: 4px;
}

.cs-dropdown ::v-deep {
  button {
    background-color: transparent !important;
    padding: 0 !important;
    border: none !important;

    &:hover {
      background-color: transparent !important;
      padding: 0 !important;
      border: none !important;
      box-shadow: none !important;
    }

    &::after {
      display: none !important;
    }
  }
}
</style>
